* {
  margin    : 0;
  padding   : 0;
  box-sizing: border-box;
  border    : none;

  font-family: Panton;
}

html {
  width          : 100%;
  height         : 100%;
  scroll-behavior: smooth;
}

body {
  width: 100%;
}

#root {
  padding-top   : env(safe-area-inset-top);
  padding-left  : env(safe-area-inset-left);
  padding-bottom: env(safe-area-inset-bottom);
  padding-right : env(safe-area-inset-right);
}

:root {
  --full-height  : calc(var(--vh, 1vh) * 100);
  /*100 vh*/
  --sidebar-width: 192px;

  --color-dark        : #0B0B12;
  --color-dark_lighter: #272839;
  --color-blue        : #00AEEF;
  --color-gray        : #e5e5e5;
}

.app {
  display: flex;
}

.app>main {
  width      : calc(100% - var(--sidebar-width));
  margin-left: var(--sidebar-width);
  min-height : var(--full-height);
  overflow-y : auto;

  background: #E5E5E5;

  overflow-x: auto;
  min-width : 1000px;
}

.app>main.full-width {
  width      : 100%;
  margin-left: 0;
  overflow-y : auto;

  background: var(--color-dark_lighter);
  color     : white;

  min-width: unset;
}

.app>main.white {
  background-color: white;
}

*:focus {
  outline: none;
}