.InputArea {
    position: relative;
    width   : max-content;
    display : inline-block;
    z-index : 5;
}

.SelectBox {
    background   : white;
    border       : 2px solid #E3E3E5;
    border-radius: 10px;
    padding      : 10px 27px 10px 27px;
    width        : 100%;

    -webkit-appearance: none;
    -moz-appearance   : none;
    appearance        : none;
}

.Dark .SelectBox {
    background     : transparent;
    /* border-color: #0B0B12; */
}

.Dark .SelectBox option {
    background-color: #0B0B12;
}

.SelectBox::-ms-expand {
    display: none;
}

.InputArea::after {
    content : '';
    position: absolute;
    top     : 15px;
    right   : 10px;
    width   : 12px;
    height  : 9px;

    background-image   : url("./assets/IconArrowDown.svg");
    background-position: center;
    background-size    : contain;
    background-repeat  : no-repeat;
    pointer-events     : none;
}

.InputArea.Dark::after {
    background-image: url("./assets/IconArrowDownLight.svg");
}

.InputArea::before {
    content : '';
    position: absolute;
    top     : 13px;
    left    : 9px;
    width   : 14px;
    height  : 13px;

    background-image   : url("./assets/globe-solid.svg");
    background-position: center;
    background-size    : contain;
    background-repeat  : no-repeat;
    pointer-events     : none;
}

.InputArea.Dark::before {
    background-image: url("./assets/globe-solid-light.svg");
}

@media(max-width: 300px) {
    .InputArea {
        left: 15px;
    }
}