.Container {
    position: absolute;
    top     : 39px;
    right   : 71px;

    display       : flex;
    flex-direction: column;
    align-items   : flex-end;
}

.Link {
    font-weight: bold;
    font-size  : 16px;
    line-height: 20px;

    text-align: right;

    color: #000000;
}

.Disabled {
    pointer-events: none;

    /* cursor: not-allowed; */
}

.Action {
    margin-top: 22px;
}

.LinkContainer {
    background   : var(--color-dark);
    border-radius: 10px;

    color      : white;
    font-weight: bold;
    font-size  : 16px;
    line-height: 20px;

    padding    : 0 20px;
    height     : 40px;
    margin-left: 16px;

    display        : inline-flex;
    justify-content: center;
    align-items    : center;
}

.LinkContainer>img {
    width : 22px;
    height: 22px;

    margin-right: 10px;
}