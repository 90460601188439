.HeaderBox {
    margin-bottom: 32px;
}

.Select {
    margin-top   : 32px;
    margin-bottom: 16px;

    display        : flex;
    justify-content: flex-end;
}

.GraphSection {
    height        : 367px;
    padding-top   : 55px;
    padding-bottom: 19px;
    padding-left  : 27px;

    background   : #FFFFFF;
    border-radius: 10px;
}