.FormContainer {
    width: 349px;
}

.FormContainer>h1 {
    font-weight: bold;
    font-size  : 34px;
    line-height: 42px;

    text-align: center;

    margin-bottom: 42px;
}

.FormContainer label {
    position: absolute;
    left    : -10000px;
    width   : 1px;
    height  : 1px;
    overflow: hidden;
}

.InputContainer {
    margin-bottom: 16px;
}

.InputContainer>input {
    width: 100%;

    background-color: transparent;
    color           : white;

    border       : 2px solid #E3E3E5;
    border-radius: 10px;

    font-weight: normal;
    font-size  : 18px;
    line-height: 22px;

    padding: 17px 20px;
}

.Button {
    background   : #00AEEF;
    border-radius: 10px;
    color        : white;

    width : 100%;
    height: 40px;

    font-weight: bold;
    font-size  : 16px;
    line-height: 20px;
}

.Toast {
    font-family: Panton;

    font-weight: normal;
    font-size  : 16px;
    line-height: 20px;

    background-color: white;
    color           : var(--color-dark);
    border          : 2px solid #E3E3E5;

}

@media(max-width: 360px) {
    .FormContainer {
        width: 100%;
    }
}