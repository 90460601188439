 .Table {
     width: 100%;

     color: #000000;

     border-collapse: collapse;
 }

 .TableHead {
     font-weight: bold;
     font-size  : 16px;

     border-bottom: 1px solid black;

     white-space: pre-line;

     vertical-align: top;
     text-align    : left;
 }

 .TableRow {
     font-weight: normal;
     font-size  : 16px;

     border-bottom: 1px solid black;

     height        : 40px;
     vertical-align: top;
 }

 .Table tbody>tr:last-of-type {
     border-bottom: 0;
 }

 .Footer {
     height        : 100px;
     vertical-align: bottom;

     font-size: 18px;
 }

 .NoData {
     font-weight: normal;
     font-size  : 18px;

     height        : 53px;
     vertical-align: middle;
     text-align    : center;
 }

 .NoWrap {
     white-space: nowrap;
 }

 .Table td, th {
     padding-right: 10px;
     padding-left : 10px;
 }