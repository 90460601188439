.Header {
    font-weight: bold;
    font-size  : 45px;
    line-height: 55px;

    color: var(--color-dark);
}

.SubHeader {
    font-weight: normal;
    font-size  : 22px;
    line-height: 27px;

    color: var(--color-dark);
}

.SubHeaderBlue {
    font-weight: normal;
    font-size  : 22px;
    line-height: 27px;

    min-height: 27px;

    color: #00AEEF;
}