@font-face {
  font-family: Panton;
  src: url("assets/font/panton_regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: Panton;
  src: url("assets/font/panton_bold.otf");
  font-weight: bold;
}

ul {
  list-style: none;
}

button {
  cursor    : pointer;
  background: transparent;
}

button:disabled {
  cursor : not-allowed;
  opacity: 0.8;
}

a {
  text-decoration: none;
  color          : inherit;
}