.TableSection {}

.Table {
    width           : 100%;
    background-color: white;
    border-radius   : 10px;

    padding-bottom: 40px;
}

.Table th {
    white-space: pre;
}

.TableHead {
    font-weight: bold;
    font-size  : 16px;
    line-height: 20px;

    color: var(--color-dark);

    height        : 62px;
    vertical-align: middle;
    text-align    : left;
}

.TableRow {
    font-weight: normal;
    font-size  : 14px;
    line-height: 17px;

    color: #000000;

    height        : 53px;
    /* vertical-align: middle; */
    vertical-align: top;
}

.Table td,
.Table th {
    padding: 0 16px 0;
}

.NoData {
    font-weight: normal;
    font-size  : 14px;
    line-height: 17px;

    color: #000000;

    height        : 53px;
    /* vertical-align: middle; */
    vertical-align: top;
    text-align    : center;
}

.ExcelTable {
    width     : 1px;
    height    : 1px;
    overflow  : hidden;
    display   : inline-block;
    background: transparent;
    display   : none;
}

.Wrap {
    overflow          : hidden;
    text-overflow     : ellipsis;
    display           : -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.NoWrap {
    white-space: nowrap;
    /* text-align : center; */
}