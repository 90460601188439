.SelectSection {
    display        : flex;
    justify-content: flex-end;
}

.SelectSection>div:first-of-type {
    margin-right: 20px;
}

.Select {
    margin-top   : 32px;
    margin-bottom: 16px;

    display        : flex;
    justify-content: flex-end;
}