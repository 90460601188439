.Container {
    padding: 40px 25px;

    max-width: 900px;
    margin: 0 auto;

    color: black;
}

.LogoContent {
    display        : flex;
    justify-content: space-between;
    align-items    : flex-end;
}

.Header {
    font-size: 28px;

    margin: 30px 0 50px;
}