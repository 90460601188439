.Container {
    height: 100%;

    padding: 44px 32px;
}

.HeaderContainer {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

.Header {
    font-weight: normal;
    font-size  : 18px;
    line-height: 22px;

    text-align: right;
}

.LoginSection {
    height: calc(100% - 52px);

    display        : flex;
    justify-content: center;
    align-items    : center;
}

@media(max-width: 400px) {
    .Container {    
        padding: 20px 16px;
    }
}