.Select {
    -webkit-appearance: none;
    -moz-appearance   : none;
    appearance        : none;
}

.Select::-ms-expand {
    display: none;
}

.Wrapper {
    position: relative;
}

.Select {
    font-weight: normal;
    font-size  : 18px;
    line-height: 22px;

    color           : var(--color-dark);
    background-color: transparent;

    border       : 2px solid rgba(39, 40, 57, 0.3);
    border-radius: 10px;

    padding-left : 20px;
    padding-right: 44px;
    height       : 40px;
}

.Light .Select {
    color       : var(--color-gray);
    border-color: var(--color-gray);
}

.Select option {
    background-color: #E5E5E5;
}

.Light .Select option {
    background-color: var(--color-dark_lighter);
}

.Wrapper::after {
    content : '';
    position: absolute;
    top     : 17.9px;
    right   : 20px;
    width   : 14px;
    height  : 7.2px;

    background-image   : url("../../../assets/svg/icon-arrow-down.svg");
    background-position: center;
    background-size    : contain;
    background-repeat  : no-repeat;
    pointer-events     : none;
}

.Wrapper.Light::after {
    background-image: url("../../../assets/svg/icon-arrow-down-wh.svg");
}