.HeaderBox {
    margin-bottom: 31px;
}

.Select {
    margin-top   : 33px;
    margin-bottom: 16px;

    display        : flex;
    justify-content: flex-end;
}

.GraphSection {
    height        : 272px;
    padding-top   : 60px;
    padding-bottom: 15px;
    padding-left  : 25px;

    background   : #FFFFFF;
    border-radius: 10px;
}

.Link {
    display        : flex;
    justify-content: flex-end;

    margin-top: 32px;
}

.Button {
    color           : white;
    background-color: #00AEEF;

    height : 40px;
    padding: 10px 16px;
    border-radius: 10px;

    font-weight: bold;
    font-size  : 16px;
    line-height: 20px;
}