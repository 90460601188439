.Address {
    text-align: right;
    font-style: normal;

    font-size: 14px;
}

.Header {
    display: block;

    font-size  : 18px;
    font-weight: bold;
}

.Address a {
    color: rgb(0, 0, 238);
}