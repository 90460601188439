.Container {
    position: relative;

    min-height: var(--full-height);

    padding-top   : 39px;
    padding-bottom: 47px;
    padding-left  : 32px;
    padding-right : 71px;

    width: 100%;
}