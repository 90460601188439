.Container {
    min-height: var(--full-height);
    background: #000000;
    text-align: center;
    color     : #E0E0E0;
}

.Container h1 {
    font-size  : 2.5rem;
    font-family: 'Permanent Marker', cursive;
}

.Container div {
    transform-style: preserve-3d;
}

.Container svg {
    width : clamp(300px, 70%, 600px);
    height: 500px;
}

.Rocket {
    transform: translateY(750px);
    animation: launch 2s ease-out forwards;
}

@keyframes launch {
    from {
        transform: translateY(750px);
    }

    to {
        perspective: 500px;
        transform  : translateY(0px);
    }
}

.Stars {
    animation: twinkling 2s linear;
}

@keyframes twinkling {

    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}

.Text {
    opacity        : 0;
    animation      : appear 1s ease-in forwards;
    animation-delay: 1.8s;
}

@keyframes appear {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.Text a {
    color          : #F66947;
    text-decoration: none;
}