.LinkItem {
}

.LinkContainer {
    display: flex;
    align-items: center;

    padding: 13px 16px;
}

.LinkContainer.Active {
    background-color: rgba(11, 11, 18, 0.4);
}

.LinkContainer svg {
    margin-right: 24px;
}

.Title {
    font-family: Panton;
    font-weight: bold;
    font-size  : 16px;
    line-height: 20px;
}

.Active>img {

}