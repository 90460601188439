.Container {
    background-color: #272839;
    color           : white;

    min-height: var(--full-height);
    width     : 192px;

    display        : flex;
    flex-direction : column;
    justify-content: space-between;

    position: fixed;
    top     : 0;
    left    : 0;
    z-index : 2;
}

.Container>div {
    min-height: fit-content;
    height    : 100%;
}

.LogoContainer {
    display       : flex;
    flex-direction: column;
    align-items   : center;

    text-align: center;

    padding: 40px 18px 23px 16px;
}

.LogoContainer>span {
    font-weight: normal;
    font-size  : 16px;
    line-height: 24px;

    margin-top: 10px;
}

.Company {
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.Company>img {
    width: 113px;

    margin: 40px auto;
}

.Currency {
    display        : flex;
    justify-content: center;
    margin         : 15px 0;
}