.Container {
    display              : grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap             : 32px;
}

.Item {
    background   : #FFFFFF;
    border-radius: 10px;

    text-align: center;

    padding: 16px 0;
}

.Title {
    font-weight: normal;
    font-size  : 16px;
    line-height: 24px;

    color: var(--color-dark)
}

.Total {
    font-weight: bold;
    font-size  : 34px;
    line-height: 42px;

    margin: 6px 0;
}

.Quarter {
    font-weight: normal;
    font-size  : 18px;
    line-height: 22px;

    color: rgba(11, 11, 18, 0.5);
}